import React, {Component} from "react";
import 'animation.gsap'
import 'debug.addIndicators'
// import '../resources/styles/pages/_website-development.scss'
import Layout from "../components/layout"
import Subgrid from "../components/subgrid"
import ImageAscii, {asciiScrollAnimation} from "../components/image-ascii"
import Utils from "../utils/utils";
import mask from '../resources/images/website-development/mask.png'
import CodicateImage from "../components/codicate-image/index";

import screen3ManIphone from "../resources/images/website-development/launch/man-iphone.svg"
import screen3ManLadder from "../resources/images/website-development/launch/man-ladder.svg"
import screen3Plant from "../resources/images/website-development/launch/plant.svg"
import screen3Rocket from "../resources/images/website-development/launch/rocket.svg"
import screen3WomanLadder from "../resources/images/website-development/launch/woman-ladder.svg"
import screen3WomanLaptop from "../resources/images/website-development/launch/woman-laptop.svg"

// import 'animation.gsap'
// import 'debug.addIndicators'
import ScrollMagic from 'scrollmagic';
import {graphql} from 'gatsby'
import {IS_MOBILE} from "../utils/constants";


class WebsiteDevelopmentPage extends Component {


    constructor(props) {
        super(props);

        this.scene = [];
        this.state = {
            isMobile: IS_MOBILE
        }

    }


    componentDidMount() {
        if(IS_MOBILE) {
            this.setState({isMobile: true})
        }

        // init controller
        const controller = new ScrollMagic.Controller();
        asciiScrollAnimation(controller,this.scene,this.sectionsTiming);

    }

    componentWillUnmount() {
        this.scene.forEach(function (el) {
            el.destroy(true);
            el = null;
        });
    }


    sectionsTiming = [
        {
            name: 'section-1',
            duration: Utils.calcDuration(0.5),
            animate: true
        },
        {
            name: 'section-2',
            duration: Utils.calcDuration(1),
            animate: false
        },
        {
            name: 'section-3',
            duration: Utils.calcDuration(1),
            animate: false
        },
        {
            name: 'section-4',
            duration: Utils.calcDuration(1.25),
            animate: true
        }
    ];

    textSlides = [
        {
            className: 'website-development',
            content:
                <>
                <strong id="large-service-title">Website Development</strong>
                <span className="subTitle">15 years of experience and 200 websites live.</span>
                <span className="subTitle">We’ve seen it all.</span>

                <div className="icons-container">
                    <span className="wp-icon"><CodicateImage img={this.props.data.wpIcon}/></span>
                    <span className="drupal-icon"><CodicateImage img={this.props.data.drupalIcon}/></span>
                </div>
                </>
        },
        {
            className: 'design-to-life',
            content:
                <>
                <span className="small-title">WEBSITE DEVELOPMENT</span>

                <strong id="large-service-title">Bringing your design to life</strong>
                <span className="subTitle">We animate, highlight and perfect your designs</span>

                </>
        },
        {
            className: 'from-design-to-launch',
            content:
                <>
                <span className="small-title">WEBSITE DEVELOPMENT</span>

                <strong id="large-service-title">From design to launch in 30 days</strong>
                <span className="subTitle">Our process produces results - on your schedule</span>

                </>
        },
        {
            className: 'loading-time',
            content:
                <>
                <span className="small-title">WEBSITE DEVELOPMENT</span>

                <strong id="large-service-title">Blasting loading time and ready for mass traffic</strong>
                <span className="subTitle">Scalable architecture and SEO ready from day one</span>

                </>
        },
    ]

    ipadWorkingImagesWidthFactor = () => this.state.isMobile ? 0.5 : 1;

    imagesSlides = () => [

        {
            className: 'screens-section-1',
            content:
                <>
                <CodicateImage blur
                    className="ipad"
                    img={this.props.data.ipad}
                />
                <CodicateImage blur
                    className="iphone"
                    img={this.props.data.iphone}
                />
                <CodicateImage blur
                    className="mac"
                    img={this.props.data.mac}
                />
                </>
        },
        {
            className: 'screens-section-2',
            content:
                <>
                <CodicateImage blur img={this.props.data.frame1}/>
                <CodicateImage blur img={this.props.data.frame2}/>
                <CodicateImage blur img={this.props.data.frame3}/>
                </>
        },
        {
            className: 'ipad-working-section-3',
            content:
                <>
                <CodicateImage img={this.props.data.screen3Ipad}/>
                <img src={screen3ManIphone} className="manIphone sliding" alt=""
                     style={{
                         width: `${8.063 * this.ipadWorkingImagesWidthFactor()}em`
                     }}
                />
                <img src={screen3ManLadder} className="manLadder sliding" alt=""
                     style={{
                         width: `${8.063 * this.ipadWorkingImagesWidthFactor()}em`
                     }}
                />
                <img src={screen3Plant} className="plant" alt=""
                     style={{
                         width: `${8.313 * this.ipadWorkingImagesWidthFactor()}em`
                     }}
                />
                <img src={screen3Rocket} className="rocket" alt=""
                     style={{
                         width: `${18.250 * this.ipadWorkingImagesWidthFactor()}em`
                     }}
                />
                <img src={screen3WomanLadder} className="womanLadder sliding" alt=""
                     style={{
                         width: `${10.438 * this.ipadWorkingImagesWidthFactor()}em`
                     }}
                />
                <img src={screen3WomanLaptop} className="womanLaptop sliding" alt=""
                     style={{
                         width: `${12.375 * this.ipadWorkingImagesWidthFactor()}em`
                     }}
                />
                </>
        },
        {
            className: 'screen-facing-front-img',
            content:
                <ImageAscii
                    image={this.props.data.asciiOriginalImage}
                    mask={mask}
                    asciiImage={this.props.data.asciiImage}
                />
        },

    ]

    render() {
        const footerOffset = Utils.calcFooterOffset(this.sectionsTiming);

        return <Layout
            className="page-website-development yellowBg"
            isIncludeFooter={true}
            footerOffset={footerOffset}
            yoastData={this.props.data.wordpressPage.yoast || null}
            seoTitle={this.props.data.wordpressPage.acf.seoTitle}
            pageTitle={this.props.data.wordpressPage.title}
        >
            <Subgrid
                textSlides={this.textSlides}
                imagesSlides={this.imagesSlides()}
                sectionsTiming={this.sectionsTiming}
                pageTitle={'Website Development'}
            />
        </Layout>
    }
}

export default WebsiteDevelopmentPage


export const query = graphql`
    query {
        wordpressPage(template: {eq: "website-development.php"}){
            title
            path
            ...YoastData
            acf {
                seoTitle: seo_title
            }
        }
        ipad: file(relativePath: { eq: "website-development/ipad.png"}) {
            childImageSharp {
                fluid(maxWidth: 1300){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        iphone: file(relativePath: { eq: "website-development/iphone.png"}) {
            childImageSharp {
                fluid(maxWidth: 1300){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        mac: file(relativePath: { eq: "website-development/mac.png"}) {
            childImageSharp {
                fluid(maxWidth: 1300){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        wpIcon: file(relativePath: { eq: "website-development/wp-icon.png"}) {
            childImageSharp {
                fluid(maxWidth: 200){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        drupalIcon: file(relativePath: { eq: "website-development/drupal-icon.png"}) {
            childImageSharp {
                fluid(maxWidth: 200){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        ipadWorking: file(relativePath: { eq: "website-development/ipadWorking.png"}) {
            childImageSharp {
                fluid(maxWidth: 1300){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        screen3Ipad: file(relativePath: { eq: "website-development/launch/ipad.png"}) {
            childImageSharp {
                fluid(maxWidth: 1000){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        frame1: file(relativePath: { eq: "website-development/screen2/frame1.png"}) {
            childImageSharp {
                fluid(maxWidth: 1400){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        frame2: file(relativePath: { eq: "website-development/screen2/frame2.png"}) {
            childImageSharp {
                fluid(maxWidth: 1400){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        frame3: file(relativePath: { eq: "website-development/screen2/frame3.png"}) {
            childImageSharp {
                fluid(maxWidth: 1400){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        asciiOriginalImage: file(relativePath: { eq: "website-development/screen-facing-front.png"}) {
            childImageSharp {
                fluid(maxWidth: 1400){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        } 
        asciiImage: file(relativePath: { eq: "website-development/ascii.png"}) {
            childImageSharp {
                fluid(maxWidth: 1400){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        } 

    }
`;